<template>
  <div>
    <h3 v-if="$store.state.layout !== 'mobile'">Orders</h3>
    <canvas ref="root"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';
import moment from 'moment';

export default {
  name: 'OrderChart',
  data() {
    return {
      dates: [],
      designs: [],
      orders: [],
      graph: null,
    };
  },

  mounted() {
    const ctx = this.$refs.root;
    let that = this;

    this.$store.subscribe(mutation => {
      let shouldUpdate = false;

      switch (mutation.type) {
        case 'admin/setOrders': {
          shouldUpdate = true;
          that.updateData();
          if (that.graph) {
            that.graph.data.datasets.forEach(dataset => {
              if (dataset.label == 'Number of Orders') {
                dataset.data = this.orders;
              }
            });
          }
          break;
        }

        case 'admin/setDesigns': {
          that.graph.data.datasets.forEach(dataset => {
            shouldUpdate = true;
            that.updateData();

            if (dataset.label === 'Number of Designs') {
              dataset.data = this.designs;
            }
          });
        }
      }

      if (shouldUpdate) {
        that.graph.update();
      }
    });

    this.updateData();

    let graph = new Chart(ctx, {
      type: 'bar',
      height: 100,
      data: {
        labels: this.dates,
        datasets: [
          {
            label: 'Number of Designs',
            data: this.designs,
            backgroundColor: [
              'rgba(54,73,93,.5)', // Blue
              'rgba(54,73,93,.5)', // Blue
              'rgba(54,73,93,.5)', // Blue
              'rgba(54,73,93,.5)', // Blue
              'rgba(54,73,93,.5)', // Blue
              'rgba(54,73,93,.5)', // Blue
              'rgba(54,73,93,.5)', // Blue
              'rgba(54,73,93,.5)', // Blue
            ],
            borderColor: ['#36495d', '#36495d', '#36495d', '#36495d', '#36495d', '#36495d', '#36495d', '#36495d'],
            borderWidth: 3,
          },
          {
            label: 'Number of Orders',
            data: this.orders,
            backgroundColor: [
              'rgba(71, 183,132,.5)', // Green
              'rgba(71, 183,132,.5)', // Green
              'rgba(71, 183,132,.5)', // Green
              'rgba(71, 183,132,.5)', // Green
              'rgba(71, 183,132,.5)', // Green
              'rgba(71, 183,132,.5)', // Green
              'rgba(71, 183,132,.5)', // Green
            ],
            borderColor: ['#47b784', '#47b784', '#47b784', '#47b784', '#47b784', '#47b784', '#47b784', '#47b784'],
            borderWidth: 3,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        lineTension: 1,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                padding: 25,
                stepSize: 1,
                suggestedMax: 10,
              },
            },
          ],
        },
      },
    });

    this.graph = graph;
    window.jon = graph;
  },
  methods: {
    updateData() {
      let dates = [];
      let orders = [];
      let designs = [];

      for (let i = 7; i >= 0; i--) {
        let formattedDate = new moment().add(-1 * i, 'days').format('MM/DD');

        dates.push(formattedDate);
        orders.push(this.$store.getters['admin/orderSummary'][formattedDate] || 0);
        designs.push(this.$store.getters['admin/designSummary'][formattedDate] || 0);
      }

      this.dates = dates;
      this.orders = orders;
      this.designs = designs;
    },
  },
};
</script>

<style lang="scss" scoped>
canvas {
  background-color: white;
  border-radius: 10px;
  max-width: 600px;
  max-height: 200px;
}
</style>
