<template>
  <div class="admin-container">
    <div class="chart-container">
      <div>
        <OrderChart />
      </div>
      <div>
        <h3>Gross Sales</h3>
        <div class="stat-value">${{ $store.getters['admin/balance'].gross }}</div>
      </div>
    </div>

    <div class="dashboard-grid">
      <div>
        <h2>Session Started</h2>
        <div class="orders">
          <OrderBox
            :order="order"
            :key="order._id"
            v-for="order in $store.getters['admin/orders']('session_started').slice(0, 5)"
          />
        </div>
      </div>

      <div>
        <h2>Being Built</h2>
        <div class="orders">
          <OrderBox :order="order" :key="order._id" v-for="order in $store.getters['admin/orders']('new_order')" />
          <OrderBox :order="order" :key="order._id" v-for="order in $store.getters['admin/orders']('uncaptured')" />
        </div>
      </div>

      <div>
        <h2>Shipped</h2>
        <div class="orders">
          <OrderBox :order="order" :key="order._id" v-for="order in $store.getters['admin/orders']('shipped')" />
        </div>
      </div>

      <div>
        <h2>Delivered</h2>
        <div class="orders">
          <OrderBox :order="order" :key="order._id" v-for="order in $store.getters['admin/orders']('delivered')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderBox from '@/components/Admin/OrderBox';
import OrderChart from '@/components/Admin/OrderChart';

export default {
  name: 'AdminDashboardViewDesktop',
  components: { OrderBox, OrderChart },
  data() {
    return {
      designs: [],
      orders: [],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.admin-container {
  width: 100%;

  .orders {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    padding: 0 60px;
    box-sizing: border-box;
  }

  .dashboard-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .chart-container {
    display: grid;
    grid-template-columns: 60% 40%;

    & > div {
      padding: 20px;
    }
  }

  .stat-value {
    font-size: 36px;
  }
}
</style>
