<template>
  <div class="order" :class="order.status" @click="$router.push({ name: 'adminOrder', params: { id: order._id } })">
    <div class="preview">
      <img
        :src="`https://${order.order_images.livepreview.bucket}.s3.amazonaws.com/${order.order_images.livepreview.key}`"
      />
    </div>

    <div class="details">
      <div>
        {{ order._id.substr(order._id.length - 5, order._id.length) }}
      </div>
      <div class="created">
        {{ order.created ? new Date(order.created).toLocaleDateString('en-US') : '---' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderBox',
  props: {
    order: Object,
  },
};
</script>

<style lang="scss" scoped>
.order {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0 0 20px 0;
  border: 1px solid gray;
  border-radius: 16px;
  margin: 20px;
  overflow: hidden;
  background-color: #e4cbcb;
  cursor: pointer;
  color: black;

  &:hover {
    border: 1px solid $tulip-tree;
  }

  &.session_started {
    background-color: gray;
  }
  &.uncaptured {
    background-color: lightgreen;
  }
  &.captured {
    background-color: lightblue;
  }

  .preview {
    background-color: white;
    width: 100%;
    padding-top: 20px;
  }

  img {
    max-width: 100px;
    margin-bottom: 10px;
  }

  .details {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .second-row {
      grid-column: span 2;
    }
  }
}
</style>
