<template>
  <div style="height: 100%;">
    <DashboardDesktop v-if="$store.state.layout === 'desktop'" />
    <DashboardMobile v-if="$store.state.layout === 'mobile'" />
  </div>
</template>

<script>
import DashboardDesktop from './DashboardDesktop';
import DashboardMobile from './DashboardMobile';

export default {
  name: 'DashboardIndex',
  components: { DashboardDesktop, DashboardMobile },
};
</script>
